export default {
  props: {
    'title': String,
    'message': String,
    'firstOption': String,
    'secondOption': String,
  },
  template: `
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <p>
          {{ message }}
        </p>
      </section>
      <footer class="modal-card-foot">
        <o-button 
          type="button" 
          @click="$emit('close', {action: firstOption} )"
        >
          {{ firstOption }}
        </o-button>
        <o-button
          v-if="secondOption !== undefined" 
          type="button" 
          @click="$emit('close', {action: secondOption} )"
        >
          {{ secondOption }}
        </o-button>
      </footer>
    </div>
  `,
};
