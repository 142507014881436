import Oruga from '@oruga-ui/oruga-next';
import {bulmaConfig} from '@oruga-ui/theme-bulma';
import Calendar from './blocks/Calendar';
import CalendarItem from './blocks/CalendarItem';
import NewsletterSignup from './blocks/NewsletterSignup';
import {createApp} from 'vue';
import Nav from './behaviors/nav';
import ExpiryDateInputField from './behaviors/expiryDateInputField';
import GiftCertificates from './behaviors/giftCertificates';
import CartCountdownTimer from './atoms/CartCountdownTimer';
import CountdownTimer from './atoms/CountdownTimer';
import RemoveLineItemButton from './atoms/RemoveLineItemButton';
import LoadingSpinner from './atoms/LoadingSpinner';
import Modals from './plugins/Modals';

const app = createApp({
  compilerOptions: {
    delimiters: ['${', '}$'],
  },

  components: {
    Calendar,
    CalendarItem,
    CartCountdownTimer,
    CountdownTimer,
    RemoveLineItemButton,
    LoadingSpinner,
    NewsletterSignup,
  },
});

app.use(Oruga, bulmaConfig);
app.use(Modals);

app.mount('#app');

document.addEventListener('DOMContentLoaded', () => {
  Nav.activate();
  ExpiryDateInputField.activate();
  GiftCertificates.activate();
});

