<template>
  <li class="event columns my-0 py-0 is-mobile is-multiline">
    <a v-if="eventEntry.imageUrl" @click="expand" :href="isEventDetail ? '' : eventEntry.url" class="image column is-narrow">
      <img :src="eventEntry.imageUrl" width="80" height="80">
    </a>
    <a @click="expand" :href="isEventDetail ? '' : eventEntry.url" class="column columns my-0 py-0 is-vcentered is-reversed-link">
      <span class="column">
        <h6 class="is-brand-subtitle mb-3">{{ event.timeStr }}</h6>
        <h4 class="is-brand-title has-text-weight-medium">{{ event.title }}</h4>
        <div v-if="(event.additionalDetails.length > 0)" class="is-brand-smaller-subtitle mt-2">{{ event.additionalDetails }}</div>
        <ul class="mt-2" v-if="eventEntry.teamMembers">
          <li class="is-text" v-for="teamMember in eventEntry.teamMembers" :key="teamMember.id">
            {{ teamMember.title }}
          </li>
        </ul>
      </span>
      <span class="column is-narrow" v-if="eventEntry.classType.label !== '' || !hasAvailability">
        <span v-if="eventEntry.classType.label === 'Online' && hasAvailability" class="tag is-light">
          <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 76 76" style="enable-background:new 0 0 76 76" xml:space="preserve"><path d="M72.9 14.4 56 25.3V22c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-3.3l16.9 10.9c1.9 1 3.1-.7 3.1-1.7V16c0-1-1.1-2.8-3.1-1.6zM52 54c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4V22c0-2.2 1.8-4 4-4h40c2.2 0 4 1.8 4 4v32zm20 2.3L56 46V30l16-10.3v36.6z"/></svg>
        </span>
        <span v-if="eventEntry.classType.label === 'Private' && hasAvailability" class="tag is-warning is-light">Private</span>
        <span v-if="!hasAvailability" class="tag is-warning is-light">Sold<br />Out</span>
      </span>
      <span class="column is-narrow">
        <button class="button is-dark" v-if="showRegistration" @click="expand">{{ hasAvailability ? 'Register' : 'Join Waitlist' }}</button>
      </span>
    </a>

    <div v-if="registrationVisible" class="column is-full">
      <p class="mb-3">There are <b>{{ availableCapacity }}</b> spots left.</p>

      <div class="attendee box is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap" v-for="(registration, index) in registrants" :key="index">

        <div class="fieldset is-flex is-flex-direction-column is-flex-grow-1">
          <div class="attendee_info fui-row fui-page-row">
            <div class="fui-field fui-page-field fui-type-single-line-text fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-text-input-field my-1" style="flex-shrink: 0;flex-basis: content;">
              <div class="fui-field-container">
                <label class="fui-label" :for="'registrant-name-' + index">Attendee Name{{ index == 0 ? '*' : '' }}</label>
                <input :id="'registrant-name-' + index" name="name" class="fui-input" :placeholder="index == 0 ? 'your name' : 'optional guest name'" v-model="registration.name" />
              </div>
            </div>
            <div class="fui-field fui-page-field fui-type-email-address fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-text-input-field  my-1" style="flex-shrink: 0;flex-basis: content;">
              <div class="fui-field-container">
                <label class="fui-label" :for="'registrant-email-' + index">Attendee Email*</label>
                <input class="fui-input" :id="'registrant-email-' + index" name="email" :placeholder="index == 0 ? 'your email' : 'guest email'" v-model="registration.email" />
              </div>
            </div>
          </div>

          <div class="dinner_row fui-row fui-page-row mt-4" v-if="eventEntry.isDinner">
            <div class="fui-field fui-page-field fui-type-email-address fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-text-input-field  my-1" style="flex-shrink: 0; flex-basis: content;">
              <div class="fui-field-container">
                <label class="fui-label" :for="'registrant-sitting-' + index">Names of attendees you'd like to sit next to:</label>
                <input class="fui-input" :id="'registrant-sitting-' + index" name="registrant-sitting"  v-model="registration.seating" />
              </div>
            </div>
            <div class="fui-field fui-page-field fui-type-dropdown fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-select-field my-1" style="flex-basis: content;">
              <div class="fui-field-container">
                <label class="fui-label" :for="'registrant-dietary-option-' + index">Dietary Option</label>
                <select  class="fui-select" :id="'registrant-dietary-option-' + index" name="dietaryOption" v-model="registration.dietaryOption">
                  <option value="omnivore">Omnivore</option>
                  <option value="vegetarian">Vegetarian</option>
                  <option value="pescatarian">Pescatarian</option>
                  <option value="gluten-free">Gluten Free</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <button class="button is-light m-4" v-if="index > 0" @click="removeRegistration(index)">Remove</button>
      </div>

      <div class="has-text-right">
        <button class="button is-link is-fullwidth-mobile m-1" @click="addRegistration">Add Another Guest</button>
        <button class="button is-dark is-fullwidth-mobile m-1" @click="addToCart">Add to Cart</button>
      </div>
    </div>

    <div v-if="waitlistVisible" class="column is-full">
      <div v-if="joinedWaitlist">
        <p class="mb-3">Thanks for your interest. We'll send you an email if a spot opens up.</p>
      </div>
      <div v-else>
        <p class="mb-3">We'll send you an email if a spot opens up.</p>

        <div class="attendee box fui-row fui-page-row is-flex is-flex-wrap-wrap is-align-items-flex-end">
          <div class="fui-field fui-page-field fui-type-single-line-text fui-label-above-input fui-subfield-label-above-input fui-instructions-above-input fui-field-required fui-text-input-field my-1" style="flex-shrink: 0; flex-basis: content;">
            <div class="fui-field-container">
              <input name="email" placeholder="Email Address" class="fui-input" v-model="waitlistEmail" />
            </div>
          </div>
          <button class="button is-dark m-1" @click="joinWaitlist">Join</button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>

import {CartHelper} from '../behaviors/commerce';

export default {
  props: {
    'event': Object,
    'show-registration': Boolean,
    'isEventDetail': Boolean,
  },
  emits: ['cartAdding', 'cartAdded', 'cartError', 'waitlistAdding', 'waitlistAdded', 'waitlistError'],
  data() {
    return {
      registrationVisible: false,
      waitlistVisible: false,
      joinedWaitlist: false,
      availableCapacity: this.event.availableCapacity,
      waitlistEmail: '',
      registrants: [],
    };
  },
  mounted() {
    if (this.hasAvailability) {
      this.addRegistration();
    }
  },
  computed: {
    eventEntry: function() {
      return this.event.relatedEvent;
    },
    hasAvailability: function() {
      return this.availableCapacity > 0;
    },
  },
  methods: {
    expand(event) {
      if (!this.isEventDetail) {
        return;
      }
      event.preventDefault();

      if (this.hasAvailability) {
        this.registrationVisible = true;
      } else {
        this.waitlistVisible = true;
      }
    },
    addRegistration() {
      if (this.registrants.length + 1 > this.availableCapacity) {
        return this.$modals.alert(
            'Limit Reached',
            `Sorry, this event only has ${this.availableCapacity} spots available.`,
            'Ok',
        );
      }

      this.registrants = [
        ...this.registrants,
        {
          name: '',
          email: '',
          dietaryOption: 'omnivore',
          seating: '',
        },
      ];
    },
    removeRegistration(index) {
      this.registrants = this.registrants.slice(0, index)
          .concat(this.registrants.slice(index + 1, this.registrants.length));
    },
    async joinWaitlist() {
      if (!this.waitlistEmail) {
        return this.$modals.alert(
            'One more thing...',
            'Please provide your email.',
            'Ok',
        );
      }

      try {
        this.$emit('waitlistAdding');

        const cartHelper = new CartHelper();
        const response = await cartHelper.joinWaitlist(this.event.id, this.waitlistEmail);

        if (!response.data.success) {
          // eslint-disable-next-line no-throw-literal
          throw {response};
        } else {
          this.joinedWaitlist = true;
        }

        this.$emit('waitlistAdded', this.event);
      } catch (error) {
        this.$emit('waitlistError');
        this.handleError(error);
      }
    },
    async addToCart() {
      const data = [...this.registrants]; // copy
      const primaryRegistrant = data[0];

      if (primaryRegistrant.name.length === 0 || primaryRegistrant.email.length < 6) {
        return this.$modals.alert(
            'One more thing...',
            'Please provide your name and email.',
            'Ok',
        );
      }

      const hasMissingGuestEmails = this.registrants.find((item) => !item.email) !== undefined;

      if (hasMissingGuestEmails) {
        return this.$modals.alert(
            'One more thing...',
            'Please provide an email address for each guest.',
            'Ok',
        );
      }
      // make sure data includes placeholder values for anything omitted by user

      const lineItems = data.map((registration, index) => {
        const options = {
          attendeeName: registration.name ? registration.name : `${primaryRegistrant.name} Guest ${index}`,
          attendeeEmail: registration.email,
        };

        if (this.eventEntry.isDinner) {
          options['attendeeDietaryOption'] = registration.dietaryOption;
          options['attendeeSeatingPreference'] = registration.seating;
        }

        return {
          purchasableId: this.event.ticketPurchasableId,
          qty: 1,
          options,
        };
      });

      try {
        this.$emit('cartAdding');

        const cartHelper = new CartHelper();
        const result = await cartHelper.addLineItems(lineItems);

        if (!result.data.success) {
          const error = new Error();
          error.response = result;
          throw error;
        }

        this.$emit('cartAdded', this.event);
      } catch (error) {
        this.$emit('cartError');
        this.handleError(error);
      }
    },
    handleError(error) {
      const responseData = error?.response?.data;

      if (responseData) {
        return this.$modals.alert(
            'Error',
            responseData.errors ? responseData.errors[0][0] : responseData.error,
            'Ok',
        );
      }
    },
  },
};

</script>
