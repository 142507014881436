import axios from 'axios';

export class CartHelper {
  csrfToken = '';

  constructor() {
    this.csrfToken = document.querySelector('input[name="CRAFT_CSRF_TOKEN"]')?.value ?? window.CSRF_TOKEN;
  }

  async addLineItems(lineItems) {
    const data = {};

    lineItems.forEach((item, index) => {
      data[`purchasables[${index}][id]`] = item.purchasableId;
      data[`purchasables[${index}][qty]`] = item.qty;

      if (item.options) {
        for (const [key, value] of Object.entries(item.options)) {
          data[`purchasables[${index}][options][${key}]`] = value;
        }
      }
    });

    return await this.makeCartRequest(data);
  }

  async joinWaitlist(eventId, email) {
    return await this.makeWaitlistRequest({
      eventId,
      email,
    });
  }

  async removeLineItem(lineItemId) {
    return await this.makeCartRequest({
      ['lineItems[' + lineItemId + '][remove]']: true,
    });
  }

  async makeCartRequest(data) {
    const defaults = {
      CRAFT_CSRF_TOKEN: this.csrfToken,
      action: 'commerce/cart/update-cart',
    };

    return await axios.post('/', new URLSearchParams({
      ...defaults,
      ...data,
    }));
  }

  async makeWaitlistRequest(data) {
    const defaults = {
      CRAFT_CSRF_TOKEN: this.csrfToken,
      action: 'pantry/waitlist/register-interest',
    };

    return await axios.post('/', new URLSearchParams({
      ...defaults,
      ...data,
    }));
  }
};
