export default {
  props: {
    'cancelable': {
      type: Boolean,
      default: true,
    },
    'containerRef': {
      type: Object,
      default: null,
    },
    'isFullPage': {
      type: Boolean,
      default: true,
    },
    'loading': {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    loading(newVal, oldVal) {
      this._toggleLoadingSpinner(newVal);
    },
  },
  methods: {
    _toggleLoadingSpinner: function(enabled=true) {
      if (enabled && !this.loadingSpinner) {
        this.loadingSpinner = this.$oruga.loading.open({
          fullPage: this.isFullPage,
          container: this.isFullPage ? null : this.containerRef,
          canCancel: this.cancelable,
        });
      } else if (!enabled && this.loadingSpinner) {
        this.loadingSpinner.close();
        this.loadingSpinner = null;
      }
    },
  },
  render() {
    return '';
  },
};
