export default {
  activate() {
    const navbarBurger = document.getElementById('nav-burger-primary');

    if (navbarBurger) {
      navbarBurger.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = navbarBurger.dataset.target;
        const parent = navbarBurger.dataset.parent;
        const $target = document.getElementById(target);
        const $parent = document.getElementById(parent);

        // Toggle the "is-active" class on both the "navbar-burger", the "navbar itself, and the "navbar-menu"
        document.body.classList.toggle('menu_open');
        navbarBurger.classList.toggle('is-active');
        $target.classList.toggle('is-active');
        $parent.classList.toggle('is-active');
        navbarBurger.setAttribute(
            'aria-expanded',
          navbarBurger.getAttribute('aria-expanded') === 'true' ?
            'false' :
            'true',
        );
      });
    }
  },
};
