<template>
  <div>
    <div v-if="showFilters" class="calendar_nav has-background-blue">
      <div class="container level px-5 py-4">
        <div class="level-left mb-0">
          <ul class="cal_types level-item">
            <li><a @click="showAllEventTypes" :class="eventTypeId === null ? 'is-active' : ''">All Events</a></li>
            <li v-for="(eventType) in eventTypes" :key="eventType.id">
              <a @click="showEventType(eventType.id)" :class="eventTypeId === eventType.id ? 'is-active' : ''">{{ eventType.label }}</a>
            </li>
          </ul>
        </div>
        <div class="level-right">
          <div class="cal_filters level-item">
            <label class="label">filter by</label>
            <span class="control">
              <div class="select is-rounded">
                <select @change="handleTagChange($event)">
                  <option value="">All Tags</option>
                  <option v-for="(tag) in tags" :key="tag.id" :value="tag.id">{{ tag.label }}</option>
                </select>
              </div>
            </span>
          </div>
          <div class="cal_views level-item">
            <label class="label">view by</label>
            <div class="buttons has-addons">
              <button @click="setGridView(true)" :class="'button is-icon has-no-border' + (gridView ? ' is-selected' : '')">Event</button>
              <button @click="setGridView(false)" :class="'button is-icon has-no-border' + (gridView ? '' : ' is-selected')">Date</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section :class="'calendar container px-5 ' + (isTeamView ? '' : 'py-6')">
      <header v-if="showFilters" class="cal_month_nav is-flex py-5 is-justify-content-center is-align-items-center">
        <h2 class="is-brand-subtitle is-order-2 mx-6">{{ monthName }}</h2>
        <button class="prev button is-icon has-no-border is-rounded is-order-1" :disabled="!hasPreviousMonth" @click="prevMonth">
          <span class="icon">
            <i class="arrow left"></i>
          </span>
          <span class="txt">Prev</span>
        </button>
        <button class="next button is-icon has-no-border is-rounded is-order-3" @click="nextMonth">
          <span class="icon">
            <i class="arrow right"></i>
          </span>
          <span class="txt">Next</span>
        </button>
      </header>

      <div class="is-flex is-justify-content-center p-2" ref="spinnerContainer">
        <p v-if="isEmpty && !isLoading" class="is-brand-large-body is-uppercase">
          No events found.
        </p>
        <loading-spinner :loading="isLoading"/>
      </div>

      <div class="columns is-centered" v-if="!gridView && dateToEventMap.size > 0">
        <div class="column is-three-quarters fui-i">
          <table class="event-list has-text-left" style="width: 100%;">
            <tr v-for="([key, dateGroup]) in dateToEventMap" class="event-group columns my-0 py-0 has-bottom-border-except-last" :key="key" >
              <td class="column is-narrow py-5 is-flex is-align-content-flex-start is-align-items-start is-flex-direction-column">
                <h4 class="is-brand-smaller-subtitle pb-2" style="opacity: 0.5;">{{ dateGroup.dayOfWeek }}</h4>
                <h3 class="is-brand-headline has-text-weight-normal ">{{ dateGroup.title }}</h3>
              </td>
              <td class="column">
                <calendar-item
                  v-for="(event) in dateGroup.events" :key="event.id"
                  :event="event"
                  :isEventDetail="isDetailView"
                  :show-registration="isDetailView"
                  @cart-adding="setLoading(true)"
                  @cart-added="eventAddedToCart"
                  @cart-error="setLoading(false)"
                  @waitlist-adding="setLoading(true)"
                  @waitlist-added="addedToWaitlist"
                  @waitlist-error="setLoading(false)"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="columns is-mobile is-multiline is-centered" v-if="gridView">
        <calendar-grid-item v-for="(entry) in eventEntries" :entry="entry" :key="entry.id" />
      </div>
    </section>
  </div>
</template>

<script>

import axios from 'axios';
import CalendarItem from './CalendarItem.vue';
import LoadingSpinner from '../atoms/LoadingSpinner';
import CalendarGridItem from './CalendarGridItem.vue';

export default {
  props: {
    'filter-data': {
      type: String,
      default: '{}',
    },
    'related-entry-id': {
      type: String,
      default: null,
    },
    'related-team-id': {
      type: String,
      default: null,
    },
    'initial-event-type': {
      type: String,
      default: null,
    },
    'show-registration': {
      type: Boolean,
      default: true,
    },
    'initial-start-date': {
      type: String,
      default: '',
    },
  },
  components: {
    CalendarItem,
    LoadingSpinner,
    CalendarGridItem,
  },
  mounted() {
    if (this.initialEventType === 'classes') {
      this.eventTypeId = this.filters.eventTypes.find((item) => item.label.includes('Class')).id;
    } else if (this.initialEventType === 'dinners') {
      this.eventTypeId = this.filters.eventTypes.find((item) => item.label.includes('Dinner')).id;
    }

    if (this.isDetailView) {
      this.gridView = false;
      this.startDate = new Date();
    }

    this.refreshItems();
  },
  data() {
    let startDate = new Date();

    let showingDefaultMonth = true;

    if (this.initialStartDate) {
      const parts = this.initialStartDate.split('-');
      startDate = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
      showingDefaultMonth = false;
    }

    return {
      dateToEventMap: new Map(),
      eventEntries: [],
      isLoading: false,
      monthName: '',
      tagId: null,
      startDate,
      eventTypeId: null,
      gridView: true,
      showingDefaultMonth,
    };
  },
  computed: {
    isEmpty: function() {
      return this.gridView ? this.eventEntries.length === 0 : this.dateToEventMap.size <= 0;
    },
    endDate: function() {
      if (this.isDetailView || this.isTeamView) {
        return new Date(this.startDate.getFullYear() + 1, this.startDate.getMonth(), 0);
      } else {
        return new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
      }
    },
    hasPreviousMonth: function() {
      return new Date() < this.startDate;
    },
    filters: function() {
      return JSON.parse(this.filterData);
    },
    eventTypes: function() {
      return this.filters.eventTypes?.map((item) => {
        return {
          id: item.id,
          label: item.label.includes('Class') ? 'Classes' : 'Dinners',
        };
      }) ?? [];
    },
    tags: function() {
      return this.filters.tags ?? [];
    },
    showFilters: function() {
      return Object.keys(this.filters).length > 0;
    },
    isDetailView: function() {
      return this.relatedEntryId !== null;
    },
    isTeamView: function() {
      return this.relatedTeamId !== null;
    },
    daysOfTheWeek: function() {
      return ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];
    },
  },
  methods: {
    hasDividerElement: function(events, index) {
      return (events.length > 1) && (index !== events.length - 1); /* apply <hr> to all but last event */
    },
    refreshItems: function() {
      if (this.gridView) {
        this.getEventEntries();
      } else {
        this.getCalendarEvents();
      }
    },
    getEventEntries: async function() {
      this.setLoading(true);

      const relatedThrough = this.tagId ? [this.tagId] : (this.relatedTeamId ? [this.relatedTeamId] : undefined);

      const params = {
        limit: 200,
        relatedThrough,
        relatedTo: this.relatedEntryId ? [this.relatedEntryId] : undefined,
        typeId: this.eventTypeId ? this.eventTypeId : undefined,
        dateRange: `${this.startDate.toISOString().slice(0, 10)},${this.endDate.toISOString().slice(0, 10)}`,
        entriesOnly: true,
      };

      try {
        const {
          data: {
            data,
            meta,
          },
        } = await axios.get('/api/events.json', {params});


        this.dateToEventMap.clear();

        // special check to see if initial results are empty and specific date was not requested already
        if (data.length === 0 && this.showingDefaultMonth) {
          this.showingDefaultMonth = false;
          this.nextMonth();
          return;
        }

        this.monthName = meta.month;

        // sort by next upcoming event date (nextEventDate)
        data.sort((a, b) => {
          const aDate = a.nextEventDate;
          const bDate = b.nextEventDate;

          if (aDate < bDate) {
            return -1;
          } else if (aDate > bDate) {
            return 1;
          } else {
            return 0;
          }
        });

        this.eventEntries = data;
        this.setLoading(false);
      } catch (error) {
        console.error('Error getting events: ', error);
        this.dateToEventMap.clear();
        this.setLoading(false);
      }
    },
    getCalendarEvents: async function() {
      this.setLoading(true);

      const relatedThrough = this.tagId ? [this.tagId] : (this.relatedTeamId ? [this.relatedTeamId] : undefined);

      const params = {
        limit: 200,
        relatedThrough,
        relatedTo: this.relatedEntryId ? [this.relatedEntryId] : undefined,
        typeId: this.eventTypeId ? this.eventTypeId : undefined,
        dateRange: `${this.startDate.toISOString().slice(0, 10)},${this.endDate.toISOString().slice(0, 10)}`,
      };

      try {
        const {
          data: {
            data,
            meta,
          },
        } = await axios.get('/api/events.json', {params});

        this.monthName = meta.month;

        this.dateToEventMap.clear();
        this.parseAndOrganizeEvents(data);
      } catch (error) {
        console.error('Error getting events: ', error);
        this.dateToEventMap.clear();
      } finally {
        this.setLoading(false);
      }
    },
    nextMonth: function() {
      this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 1);
      this.showingDefaultMonth = false;
      this.refreshItems();
    },
    prevMonth: function() {
      if (!this.hasPreviousMonth) return;

      this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() - 1, 1);
      this.showingDefaultMonth = false;
      this.refreshItems();
    },
    showAllEventTypes: function() {
      this.eventTypeId = null;
      this.refreshItems();
    },
    showEventType: function(eventTypeId) {
      this.eventTypeId = eventTypeId;
      this.refreshItems();
    },
    setGridView: function(value) {
      this.gridView = value;
      this.refreshItems();
    },
    handleTagChange: function({target}) {
      this.tagId = target.value === '' ? null : parseInt(target.value);
      this.refreshItems();
    },
    parseAndOrganizeEvents: function(events) {
      events.forEach((event) => {
        const eventDate = new Date(event.startDate);
        const dateStr = eventDate.toLocaleDateString('en-US', {day: '2-digit', month: 'short', timeZone: 'America/Los_Angeles'});
        const timeStr = eventDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', timeZone: 'America/Los_Angeles'});
        const dayOfWeek = this.daysOfTheWeek[eventDate.getDay()];

        event.timeStr = timeStr;

        let dateGroup = null;

        if (this.dateToEventMap.has(dateStr)) {
          dateGroup = this.dateToEventMap.get(dateStr);
        } else {
          dateGroup = {
            title: dateStr,
            dayOfWeek,
            events: [],
          };
          this.dateToEventMap.set(dateStr, dateGroup);
        }

        dateGroup?.events.push(event);
      });
    },
    setLoading: function(loading=false) {
      if (loading && !this.isLoading) {
        this.isLoading = true;
      } else if (!loading && this.isLoading) {
        this.isLoading = false;
      }
    },
    eventAddedToCart: async function(event) {
      this.setLoading(false);

      await this.$modals.alert(
          'Success',
          `Tickets for ${event.title} have been added to your cart.`,
          'Ok',
      );

      window.location = '/cart';
    },
    addedToWaitlist: async function(event) {
      this.setLoading(false);

      await this.$modals.alert(
          'Success',
          `You've been added to the waitlist for ${event.title}.`,
          'Ok',
      );
    },
  },
};
</script>
