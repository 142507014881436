import CountdownTimer from './CountdownTimer';

export default {
  props: {
    'initial-time-in-seconds': Number,
    'hidden': Boolean,
  },
  mounted() {
    if (this.initialTimeInSeconds > 0 && this.initialTimeInSeconds < 10000) {
      this.launchTimerComponent();
    }
  },
  methods: {
    launchTimerComponent() {
      this.$oruga.notification.open({
        position: 'bottom-right',
        variant: this.hidden ? 'hidden' : 'danger',
        component: CountdownTimer,
        props: {
          'initial-time-in-seconds': this.initialTimeInSeconds,
          'link-url': '/cart',
        },
        events: {
          'expired': this.timerExpired,
        },
        duration: (this.initialTimeInSeconds * 1000) + 500,
      });
    },
    async timerExpired() {
      await this.$modals.alert(
          'Checkout Time Expired',
          'Some items in your cart have expired.',
          'Ok',
      );

      window.location.reload();
    },
  },
  render() {
    return '';
  },
};
