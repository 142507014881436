import {CartHelper} from '../behaviors/commerce';

export default {
  props: {
    'line-item-id': Number,
  },
  mounted() {
    this.cartHelper = new CartHelper();
  },
  methods: {
    removeLineItem: async function(event) {
      event.preventDefault();
      await this.cartHelper.removeLineItem(this.lineItemId);
      window.location.reload();
      // TODO: loader?
    },
  },
  template: `<button class="delete" aria-label="delete" @click="removeLineItem"></button>`,
};
