export default {
  activate() {
    const form = document.querySelector('.fui-form');

    if (form) {
      const jsonConfigData = form.getAttribute('data-config');
      const config = JSON.parse(jsonConfigData);

      if (config && config.formHandle === 'giftCard') {
        form.setAttribute('autocomplete', 'off');
      }
    }

    const addressInputContainer = document.querySelector('[data-field-handle=\'recipientAddress\']');
    const emailInputContainer = document.querySelector('[data-field-handle=\'recipientEMailAddress\']');
    const deliveryMethodSelectElement = document.querySelector('[data-field-handle=\'deliveryMethod\']');

    if (addressInputContainer && emailInputContainer && deliveryMethodSelectElement) {
      const emailInput = emailInputContainer.querySelector('input');
      const addressInput = addressInputContainer.querySelector('input');

      deliveryMethodSelectElement.addEventListener('change', ({target}) => {
        if (target.value === 'physical') {
          emailInput.value = '';
          emailInput.removeAttribute('required');
          addressInput.setAttribute('required', '');
          addressInput.removeAttribute('disabled');
          emailInputContainer.style.display = 'none';
          addressInputContainer.style.display = 'block';
        } else if (target.value === 'digital') {
          addressInput.value = '';
          emailInput.setAttribute('required', '');
          emailInput.removeAttribute('disabled');
          addressInput.removeAttribute('required');
          emailInputContainer.style.display = 'block';
          addressInputContainer.style.display = 'none';
        } else if (target.value === 'pickup') {
          emailInput.value = '';
          addressInput.value = '';
          emailInput.removeAttribute('required');
          addressInput.removeAttribute('required');
          emailInputContainer.style.display = 'none';
          addressInputContainer.style.display = 'none';
        }
      });
    }
  },
};
