<template>
  <div v-if="isNavBarSignup" class="newsletter_signup" @click="openFormModal()">
    <img class="" src="/assets/images/icons/arrow.svg" alt="Arrow" aria-hidden="true" width="33">
    <span>Sign Up for our Newsletter</span>
  </div>
  <a v-else class="button is-lined" @click="openFormModal()">Subscribe Now</a>
</template>

<script>
export default {
  props: {
    form: String,
    navBarSignup: String,
  },
  computed: {
    isNavBarSignup: function() {
      return JSON.parse(this.navBarSignup);
    },
  },
  methods: {
    openFormModal() {
      this.$modals.form(
          'Our Newsletter',
          'Sign up below to receive announcements about upcoming classes, dinners and special events!',
          'Cancel',
          this.form,
      );
    },
  },
};
</script>
