import DialogBox from '../atoms/DialogBox';
import FormDialogBox from '../atoms/FormDialogBox';

export default {
  install: (app, options) => {
    const $oruga = app.config.globalProperties.$oruga;

    const openModal = async (title, message, firstOption, secondOption) => {
      const modalInstance = $oruga.modal.open({
        component: DialogBox,
        props: {
          'title': title,
          'message': message,
          'firstOption': firstOption,
          'secondOption': secondOption,
        },
        trapFocus: true,
      });

      return await modalInstance.promise;
    };

    const openFormModal = async (title, subHeadline, firstOption, form) => {
      const modalInstance = $oruga.modal.open({
        component: FormDialogBox,
        props: {
          'title': title,
          'subHeadline': subHeadline,
          'firstOption': firstOption,
          'form': form,
        },
        trapFocus: true,
      });

      return await modalInstance.promise;
    };

    app.config.globalProperties.$modals = {
      form: async (title, subHeadline, firstOption='Ok', form) => {
        return await openFormModal(title, subHeadline, firstOption, form);
      },
      prompt: async (title, message, firstOption='Ok', secondOption='Cancel') => {
        return await openModal(title, message, firstOption, secondOption);
      },
      alert: async (title, message, closeOption='Ok') => {
        return await openModal(title, message, closeOption, undefined);
      },
    };
  },
};
