export default {
  activate() {
    const expiryDateInputElement = document.querySelector('div[data-field-handle=\'expDate\']');

    const keyCodeIsNumber = ({keyCode}) => {
      return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    };

    const handleKeyPress = (event) => {
      const dateStrLength = event.target.value.length;

      /* ignore this block on backspace */
      if (dateStrLength === 2 && event.keyCode !== 8) {
        let dateStr = event.target.value;
        dateStr += '/';
        event.target.value = dateStr;
      /* remove non-number values after slash, in case user didn't notice the added slash */
      } else if (dateStrLength === 4 && !keyCodeIsNumber(event)) {
        const dateStr = event.target.value;
        event.target.value = dateStr.slice(0, dateStr.length - 1);
      }
    };

    if (expiryDateInputElement) {
      expiryDateInputElement.addEventListener('keyup', handleKeyPress);
      expiryDateInputElement.addEventListener('keydown', handleKeyPress);
    }
  },
};
