import LoadingSpinner from './LoadingSpinner';

export default {
  props: {
    'initial-time-in-seconds': Number,
    'linkUrl': {default: null, type: String},
    'showIcon': Boolean,
  },
  components: {
    LoadingSpinner,
  },
  emits: ['expired'],
  mounted() {
    this.timeLeftInSeconds = this.initialTimeInSeconds;
    this.startTimer(1000, 30);
  },
  data() {
    return {
      intervalId: null,
      timeLeftInSeconds: 0,
      isLoading: false,
    };
  },
  computed: {
    formattedTimeRemaining: function() {
      let minutes = Math.floor(this.timeLeftInSeconds / 60);
      let seconds = this.timeLeftInSeconds % 60;

      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    startTimer: function(interval = 1000, warningDuration) {
      this.intervalId = setInterval(() => {
        this.timeLeftInSeconds -= 1;

        if (this.timeLeftInSeconds === warningDuration) {
          this.triggerCartRemovalSpinner(warningDuration);
        } else if (this.timeLeftInSeconds <= 0) {
          this.timeExpired();
        }
      }, interval);
    },
    timeExpired: async function() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      this.$emit('expired');
    },
    triggerCartRemovalSpinner: function(warningDuration = 30) {
      this.setLoading(true);

      setTimeout(() => {
        this.setLoading(false);
      }, (warningDuration * 1000));
    },
    setLoading: function(loading=false) {
      if (loading && !this.isLoading) {
        this.isLoading = true;
      } else if (!loading && this.isLoading) {
        this.isLoading = false;
      }
    },
  },
  template: `
      <span class="countdown-timer is-size-5 is-family-monospace">
        <loading-spinner :loading="isLoading"/>
        <a v-if="linkUrl" :href="linkUrl">
          <svg class="icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19"><path d="M2.026 14.619.868 6.75h20.264l-1.158 7.869a4.25 4.25 0 0 1-4.205 3.631H6.23a4.25 4.25 0 0 1-4.205-3.631ZM10 1 6 9m7-8 3 8" stroke="currentColor" stroke-width="1.5"></path></svg>
          {{ formattedTimeRemaining }}
        </a>
        <span v-if="!linkUrl">{{ formattedTimeRemaining }}</span>
      </span>
    `,
};
