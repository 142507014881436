<template>
  <div class="cal_grid_item column is-block is-full-mobile is-half-tablet is-one-quarter-desktop is-flex">
    <a :href="entry.url" :class="'card my-4 is-flex is-flex-direction-column' + (soldOut ? ' sold-out' : '')">
      <div class="card-image has-layers">
        <span v-if="onlineEvent && !soldOut" class='tag has-background-mustard'>
          <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 76 76" style="enable-background:new 0 0 76 76" xml:space="preserve"><path d="M72.9 14.4 56 25.3V22c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-3.3l16.9 10.9c1.9 1 3.1-.7 3.1-1.7V16c0-1-1.1-2.8-3.1-1.6zM52 54c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4V22c0-2.2 1.8-4 4-4h40c2.2 0 4 1.8 4 4v32zm20 2.3L56 46V30l16-10.3v36.6z"/></svg>
        </span>
        <span v-if="privateEvent && !soldOut" class='tag has-background-blue'>PRIVATE</span>
        <span v-if="soldOut" class='tag has-background-tomato has-text-white'>SOLD<br />OUT</span>
        <figure class="image is-on-bottom is-5by3">
          <img :src="entry.imageUrl" />
        </figure>
      </div>
      <div class="card-content has-text-left" style="flex-grow: 1;">
        <h1 class="is-brand-title has-text-weight-medium is-text">{{ entry.title }}</h1>
        <h2 class="mt-2">
          {{ teamMembers }}
        </h2>
      </div>
    </a>
  </div>
</template>

<script>

export default {
  props: {
    'entry': Object,
  },
  computed: {
    onlineEvent: function() {
      return this.entry.classType.label === 'Online';
    },
    privateEvent: function() {
      return this.entry.classType.label === 'Private';
    },
    soldOut: function() {
      return this.entry.soldOut;
    },
    teamMembers: function() {
      return this.entry.teamMembers.map((item) => {
        return item.title;
      }).join(', ');
    },
  },
};
</script>
