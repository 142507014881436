export default {
  props: {
    'title': String,
    'subHeadline': String,
    'firstOption': String,
    'form': String,
  },
  computed: {
    parsedForm: function() {
      return JSON.parse(this.form);
    },
  },
  mounted() {
    if (window.Formie) {
      window.Formie.initForms();
    }
  },
  template: `
    <div class="modal-card newsletter-modal">
      <section class="hero modal-card-head">
        <div class="columns">
          <div class="column hero_content is-12-mobile is-offset-0-mobile is-8-desktop is-offset-2-desktop my-6">
            <h2 class="is-brand-larger-headline has-text-centered mb-5">{{title}}</h2>
            <p class="is-brand-large-body simple-hero-intro has-text-centered">{{ subHeadline }}</p>
          </div>
        </div>
      </section>
      <section class="modal-card-body">
      <section class="section pt-0">
        <div class="container">
          <div class="columns is-centered">
            <div v-html="parsedForm" class="column txt is-half has-text-left"></div>
          </div>
        </div>
      </section>
      </section>
      <footer class="modal-card-foot pt-1">
        <o-button
          type="button"
          @click="$emit('close', {action: firstOption} )"
        >
          {{ firstOption }}
        </o-button>
      </footer>
    </div>
  `,
};
